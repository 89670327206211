.welcomePage {
  background-image: url('../../assets/welcomeImage.jpg') !important;
}

@media(max-width: 768px) {
  .welcomePage {
    background-position: 90% 100% !important;
  }
}

@media(max-width: 500px) {
  .welcomePage {
    background-image: linear-gradient(#c2d0bd, #c0cab7) !important;
  }
}